<template>
  <div>
    <v-alert v-if="active" type="error">
      The user name is incorrect. Try again.
    </v-alert>
    <v-card class="px-4">
      <v-card-title> Sign In </v-card-title>
      <v-card-text>
        <v-form
          v-model="validate"
          @submit.prevent="login"
          ref="form"
          lazy-validation
        >
          <v-row>
            <!--          <v-col cols="12">
              <v-text-field
                v-model="email"
                label="E-mail"
                :rules="[rules.required, rules.loginEmailRules]"
                required
              ></v-text-field>
            </v-col> -->
            <v-col cols="12">
              <v-text-field
                v-model="name"
                label="Name"
                :rules="[rules.required, rules.fieldMustBeWithoutSpaces]"
                required
                @keydown.enter="login"
              ></v-text-field>
            </v-col>
            <v-col class="d-flex" cols="12" sm="6" xsm="12"> </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="password"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                hint="At least 8 characters"
                counter
                :rules="[rules.required, rules.passwordMustBeWithoutSpaces]"
                @click:append="show1 = !show1"
                @keydown.enter="login"
              ></v-text-field>
            </v-col>

            <v-spacer></v-spacer>
            <v-col
              class="d-flex justify-center"
              cols="12"
              sm="3"
              xsm="12"
              align-center
            >
              <v-btn
                @click="login"
                :loading="loading"
                x-large
                block
                color="success"
              >
                Login
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import formValidationMixin from "@/shared/validationRules";

export default {
  data: () => ({
    email: "",
    password: "",
    name: "",
    remember: false,
    active: false,
    show1: false,
    valid: false,
    validate: false,
    loading: false,
  }),
  computed: {
    ...mapGetters("auth", ["auth", "getCurrentUser"]),
  },
  mixins: [formValidationMixin],
  methods: {
    ...mapActions("auth", ["loginUser"]),
    ...mapActions("clientSelection", ["fetchCustomers"]),
    ...mapMutations("clientSelection", ["setCustomersArray"]),
    async login() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          await this.loginUser({
            name: this.name.toLowerCase().trim(),
            password: this.password.trim(),
          });
          await this.setCustomersArray([]);
          await this.fetchCustomers({ id: this.getCurrentUser.id });
          await this.$router.push("/client-selection-page");
        } catch (e) {
          this.active = true;
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
